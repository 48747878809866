<template>
  <div>
    <!-- 统计 -->
    <div class="card">
      <h4>
        基本信息
      </h4>
      <div class="count-list flex">
        <div class="count-item" @click="navigateTo('managerGoods')">
          <div>
            <i class="el-icon-s-goods"></i>
          </div>
          <div>
            <div class="counts">{{ mallStat.salesToday || 0 }}<span>元</span></div>
            <div>今日销售额</div>
          </div>
        </div>
        <div class="count-item" @click="navigateTo('memberList')">
          <div>
            <i class="el-icon-s-custom"></i>
          </div>
          <div>
            <div class="counts">{{ mallStat.salesMonth || 0 }}<span>元</span></div>
            <div>本月销售额</div>
          </div>
        </div>
      </div>
    </div>

    <div class="card flex">
      <div class="chart-item">
        <h4>交易走势</h4>
        <div id="recentChart" :style="{width: '100%', height: '400px'}">
        </div>
      </div>
      <div class="chart-item">
        <h4>今日销售菜品TOP10</h4>
        <div>
          <el-table
              :data="mallStat.statGoodsNameToday"
              style="width: 100%">
            <el-table-column
                prop="goodsName"
                label="商品名">
            </el-table-column>
            <el-table-column
                prop="amountStr"
                label="重量/数量">
            </el-table-column>
            <el-table-column
                prop="payAmount"
                label="销售额(元)">
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="chart-item">
        <h4>本月销售菜品TOP10</h4>
        <div>
          <el-table
              :data="mallStat.statGoodsNameMonth"
              style="width: 100%">
            <el-table-column
                prop="goodsName"
                label="商品名">
            </el-table-column>
            <el-table-column
                prop="amountStr"
                label="重量/数量">
            </el-table-column>
            <el-table-column
                prop="payAmount"
                label="销售额">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMallStat } from '@/api/mall/stat'
import * as echarts from 'echarts'
import { getToken } from '@/util/auth'

export default {
  name: 'Home',
  data () {
    return {
      mallStat: {
        salesToday: null,
        salesMonth: null,
        statGoodsNameToday: [],
        statGoodsNameMonth: [],
        recentOrder: []
      },
      recentOptions: {
        title: {
          text: '最近七天销售统计(元)'
        },
        tooltip: {},
        xAxis: {
          data: []
        },
        yAxis: {},
        series: [
          {
            name: '销量',
            type: 'line',
            data: [],
            label: {
              show: true,
              position: 'top',
              textStyle: {
                fontSize: 15
              }
            }
          }
        ]
      }
    }
  },
  created () {
    this.loadData()
  },

  methods: {
    loadData () {
      getMallStat().then(res => {
        if (res.success) {
          this.mallStat = res.data
          this.recentOptions.xAxis.data = []
          this.recentOptions.series[0].data = []
          // 基于准备好的dom，初始化echarts实例
          const myChart = echarts.init(document.getElementById('recentChart'))
          // 绘制图表
          this.mallStat.recentOrder.forEach(item => {
            this.recentOptions.xAxis.data.push(item.date)
            this.recentOptions.series[0].data.push(item.salesAmount)
          })
          myChart.setOption(this.recentOptions)
        } else {
          if (res.code === 4103) {
            window.app.refreshToken(getToken())
          }
        }
      })
    }
  },
  mounted () {
  }
}
</script>
<style lang="scss" scoped>
@import "./home.scss";
</style>
