import { request } from '@/util/http'

export const getMallStat = () => {
  return request({
    url: '/lib/stat/chart',
    method: 'get'
  })
}

export const getOrderList = (current, size, params) => {
  return request({
    url: '/lib/stat/order/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getOrderInfo = (orderId) => {
  return request({
    url: '/lib/stat/order/items/' + orderId,
    method: 'get'
  })
}

export const groupName = (current, size, params) => {
  return request({
    url: '/lib/stat/group/name',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const groupPay = (params) => {
  return request({
    url: '/lib/stat/group/pay',
    method: 'get',
    params
  })
}
